import React from "react"

class Employee extends React.Component {
  render() {
    const { imgSrc, name, position } = this.props
    return (
      <div class="col-md-4 col-sm-6">
        <div
          class="team-thumb wow fadeInUp animated animated-staff-description"
          data-wow-delay="0.2s"
        >
          <img src={`../staffPhotos/${imgSrc}`} class="img-responsive" alt="" />

          <div class="team-info">
            <h3>{name}</h3>
            <p>{position}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Employee
