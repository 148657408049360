import React from "react"

class EmployeeHeader extends React.Component {
  render() {
    const { name, id } = this.props
    return (
      <div class="col-md-12 col-sm-12">
        <div class="about-info">
          <h2
            id={id}
            class="wow fadeInUp animated animated-staff-info text-center"
            data-wow-delay="0.1s"
          >
            {name}
          </h2>
        </div>
      </div>
    )
  }
}

export default EmployeeHeader
