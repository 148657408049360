import React from "react"
import Helmet from "react-helmet"

import { Link, withPrefix } from "gatsby"
import Layout from "../components/layout"
import Employee from "../components/employee"
import EmployeeHeader from "../components/employeeHeader"
import SEO from "../components/seo"

import { rhythm } from "../utils/typography"
import Button from "../components/button"

class StaffPage extends React.Component {
  render() {
    return (
      <Layout isShowNav={false}>
        <Helmet>
          <script src={withPrefix("jquery.js")} type="text/javascript" />
          <script src={withPrefix("jquery.sticky.js")} type="text/javascript" />
          <script
            src={withPrefix("jquery.stellar.min.js")}
            type="text/javascript"
          />
          <script src={withPrefix("wow.min.js")} type="text/javascript" />
          <script src={withPrefix("custom.js")} type="text/javascript" />
        </Helmet>
        <SEO title="Personel" />
        <section
          data-stellar-background-ratio="0.5"
          style={{ marginTop: "40px" }}
        >
          <div class="container">
            <div class="row">
              <EmployeeHeader name="Lekarze" id="doctors" />
              <Employee
                imgSrc="team-image3.jpg"
                name="Lucyna Broniek"
                position="Lekarz rodzinny, pediatra"
              />
              <Employee
                imgSrc="team-image2.jpg"
                name="Arkadiusz Jagła"
                position="Lekarz chorób wewnętrznych"
              />
              <Employee
                imgSrc="team-image1.jpg"
                name="Maria Michalik"
                position="Lekarz rodzinny, pediatra"
              />
              <Employee
                imgSrc="team-image4.jpg"
                name="Teresa Mazur-Jagła"
                position="Lekarz specjalista ginekolog-położnik-cytolog"
              />
            </div>
            <div class="row">
              <EmployeeHeader name="Pielęgniarki" id="nurses" />
              <Employee
                imgSrc="team-image4.jpg"
                name="Aleksandra Kiełbasa"
                position="mgr pielęgniarstwa, Kierownik ds. administracyjno-pielęgniarskich"
              />
              <Employee
                imgSrc="team-image1.jpg"
                name="Małgorzata Biernacka"
                position="Pielegniarka dyplomowana"
              />
              <Employee
                imgSrc="team-image6.jpg"
                name="Joanna Fryczek"
                position="mgr pielęgniarstwa, specjalista pielęgniarstwa rodzinnego"
              />
              <Employee
                imgSrc="team-image3.jpg"
                name="Zofia Ligęza"
                position="Pielęgniarka dyplomowana, pielęgniarka środowiskowo-rodzinna"
              />
              <Employee
                imgSrc="team-image4.jpg"
                name="Danuta Mentel"
                position="Pielęgniarka dyplomowana, pielęgniarka środowiskowo-rodzinna"
              />
              <Employee
                imgSrc="team-image6.jpg"
                name="Renata Rząca"
                position="Pielegniarka środowiska nauczania i wychowania"
              />
              <Employee
                imgSrc="team-image1.jpg"
                name="Agnieszka Śliwa"
                position="Pielęgniarka dyplomowana, pielęgniarka środowiskowo-rodzinna"
              />
              <Employee
                imgSrc="team-image2.jpg"
                name="Joanna Szymczyk"
                position="lic. pielęgniarstwa, pielęgniarka środowiskowo-rodzinna"
              />
              <Employee
                imgSrc="team-image3.jpg"
                name="Edyta Wojtaczka"
                position="lic. pielęgniarstwa, pielęgniarka środowiskowo-rodzinna"
              />
            </div>
            <div class="row">
              <EmployeeHeader name="Położne" id="midwives" />
              <Employee
                imgSrc="team-image2.jpg"
                name="Bożena Gliwa"
                position="Położna dyplomowana, położna środowiskowo-rodzinna"
              />
              <Employee
                imgSrc="team-image5.jpg"
                name="Danuta Bogusz"
                position="Położna dyplomowana, położna środowiskowo-rodzinna"
              />
            </div>
            <div class="row">
              <EmployeeHeader name="Fizjoterapeuci" id="rehabilitation" />
              <Employee
                imgSrc="team-image3.jpg"
                name="Teresa Broniek"
                position="mgr fizjoterapii"
              />
              <Employee
                imgSrc="team-image4.jpg"
                name="Justyna Magiera"
                position="mgr fizjoterapii"
              />
              <Employee
                imgSrc="team-image1.jpg"
                name="Kinga Ferenc"
                position="mgr fizjoterapii"
              />
              <Employee
                imgSrc="team-image2.jpg"
                name="Dorota Smoszna"
                position="mgr fizjoterapii"
              />
              <Employee
                imgSrc="team-image6.jpg"
                name="Przemysław Nikiel"
                position="mgr fizjoterapii"
              />
              <Employee
                imgSrc="team-image5.jpg"
                name="Przemysław Wojtas"
                position="lic. fizjoterapii"
              />
            </div>
            <hr
              style={{
                marginBottom: rhythm(1),
              }}
            />
            <Link to="/">
              <Button className="section-btn btn btn-default btn-blue">
                Strona główna
              </Button>
            </Link>
          </div>
        </section>
      </Layout>
    )
  }
}

export default StaffPage
